// Sample irame
// <iframe src="https://www.google.com/maps/d/embed?mid=1U1vFKjYdeeGYPGk74z0BTCq0EHJd91rZ" width="640" height="480"></iframe>

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

const styles = `
pre {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  white-space: pre-wrap;
}
`
/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const EmbedPage = ({ data, location, pageContext }) => {
  const page = data.ghostPage
  const { isAmp } = pageContext

  return (
    <>
      <MetaData
        data={{}}
        location={location}
        type="article"
      />
      <Helmet>
        <style amp-custom="true" type="text/css">
          {styles}
        </style>
      </Helmet>

      <Layout isAmp={isAmp}>
        <h1>{page.title}</h1>
        <article dangerouslySetInnerHTML={{ __html: page.html }} />

        <h3>Live Demos</h3>
        <h4>English</h4>
        <div data-stackid="f0620d900aef5ad46afd4e2d8a1a2cff" class="dml-widget-container"></div>

        <h4>Español</h4>
        <div data-stackid="d57b3fc5ddd17ae2ba7ea312089bb7db" class="dml-widget-container"></div>

      </Layout>
    </>
  )
}

EmbedPage.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default EmbedPage

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
